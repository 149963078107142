globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"8d788caf6ff7904b83a04a74e745cfd8445e3a61"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config added here will be used whenever a users loads a page in their browser.

import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  tracesSampleRate: 0.75,
  debug: false,
  environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
});
