import Image from '@/components/atoms/Image';
import Link from '@/components/atoms/Link';
import { AnalyticsEvent } from '@/lib/handleActionTracking';
import clsx from 'clsx';
import { useCallback, useEffect, useRef, useState } from 'react';
import { NoImageWrapper } from '../NoImageWrapper/NoImageWrapper';

interface DynamicImageWrapperProps {
  alt: string;
  src?: string;
  make?: string;
  className?: string;
  priority?: boolean;
  isColumnLayout?: boolean;
  ratio?: number;
  analyticsEvent?: AnalyticsEvent;
  noImageWrapperStyle?: string;
  href?: {
    pathname: string;
    query: {
      listingId?: string;
      vin?: string;
    };
  };
  onClick?: () => void;
  ariaLabel?: string;
}

export const DynamicImageWrapper: React.FC<DynamicImageWrapperProps> = ({
  src = null,
  alt,
  make,
  className,
  noImageWrapperStyle,
  priority,
  isColumnLayout = true,
  ratio = 0.7,
  href,
  analyticsEvent,
  onClick,
  ariaLabel,
}) => {
  const imageWrapperRef = useRef<HTMLDivElement>(null);
  const [imageHeight, setImageHeight] = useState(258);
  const [imageWidth, setImageWidth] = useState(368);
  const [error, setError] = useState<boolean>(false);
  const handleImageError = () => {
    setError(true);
  };
  const calculateImageDimension = useCallback(() => {
    if (imageWrapperRef.current) {
      const dynamicHeight = imageWrapperRef.current.offsetWidth * ratio;
      const dynamicWidth = imageWrapperRef.current.offsetWidth;

      setImageHeight(Math.round(dynamicHeight));
      setImageWidth(Math.round(dynamicWidth));
    }
  }, [ratio]);

  useEffect(() => {
    calculateImageDimension();
  }, [calculateImageDimension, isColumnLayout]);

  useEffect(() => {
    window.addEventListener('resize', calculateImageDimension);
    return () => {
      window.removeEventListener('resize', calculateImageDimension);
    };
  }, [calculateImageDimension]);

  const staticImage =
    error || !src ? (
      <NoImageWrapper
        make={make}
        className={`m-auto mb-s flex-col items-center justify-center text-blue-dark opacity-40 ${noImageWrapperStyle}`}
        fillColor="#0E326A"
        height="65px"
        width="64px"
      />
    ) : (
      <Image
        className={`relative h-full w-full object-fill object-center ${isColumnLayout ? '' : 'scale-120'} ${className}`}
        src={src}
        alt={alt}
        priority={priority}
        fill
        sizes={`${imageWidth}px`}
        onError={handleImageError}
      />
    );

  return (
    <div
      className={clsx(
        'relative flex h-full w-full items-center justify-center',
        {
          'bg-gradient-to-r from-lightTheme-start to-lightTheme-end':
            error || !src,
        }
      )}
      ref={imageWrapperRef}
      onClick={onClick}
      style={{ height: `${imageHeight}px` }}
    >
      {href ? (
        <Link
          href={href}
          aria-label={ariaLabel}
          analyticsEvent={analyticsEvent}
          className="h-full w-full"
          childrenStyle="w-full flex items-center"
          prefetch={priority}
        >
          {staticImage}
        </Link>
      ) : (
        staticImage
      )}
    </div>
  );
};
