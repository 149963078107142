import Icon from '@/components/atoms/legacy/Icon';
import { makeIconId } from '@/lib/make';

interface MakeTileProps {
  make: string;
  handleMakeClick?: (
    event: React.MouseEvent<HTMLDivElement>
  ) => void | Promise<void>;
  selected: boolean;
  needChange?: boolean;
  handleChange?: () => void;
}

export const MakeTile = ({
  make,
  handleMakeClick,
  selected,
  needChange = false,
  handleChange,
}: MakeTileProps) => {
  const iconId = make ? makeIconId(make) : null;
  return (
    <>
      <div
        key={make}
        className={`inline-flex h-14 w-full cursor-pointer items-center justify-between gap-4 rounded border bg-white py-2.5 pl-2.5 pr-5 ${
          selected ? 'border-2 border-black' : 'border-gray-300'
        }`}
        onClick={handleMakeClick}
      >
        <div className="flex items-center justify-start gap-4">
          <div className="flex h-10 w-10 items-center justify-center gap-2.5 rounded bg-gray-50 p-1">
            <div className="relative h-6 w-6">
              {iconId && (
                <Icon
                  className="m-auto mb-s"
                  height={25.5}
                  width={25.5}
                  iconId={iconId}
                />
              )}
            </div>
          </div>
          <div className="shrink grow basis-0 text-base font-medium leading-snug tracking-tight text-neutral-800">
            {make}
          </div>
        </div>
        {needChange && (
          <div
            className="mr-[6px] border-b-2 border-b-electricGreen-70 text-bodyBold4 text-sleetGrey-20"
            onClick={handleChange}
          >
            <span>Change</span>
          </div>
        )}
      </div>
    </>
  );
};
