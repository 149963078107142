import { getPublicIpAddress } from '@/lib/getPublicIpAddress';
import { useEffect, useState } from 'react';

interface BaseAddress {
  postal: string;
  city: string;
}
export interface Address extends BaseAddress {
  state: string;
  country: string;
}

/**
 * Gets the user's Address (based on ip location)
 */
export const useUserLocation = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const getLocalAddress = () => {
    if (typeof window === 'undefined') {
      return undefined;
    }
    try {
      const sessionZip = localStorage.getItem('userAddress');
      if (sessionZip !== null) {
        return JSON.parse(sessionZip) as Address;
      }
    } catch (e) {
      return undefined;
    }
    return undefined;
  };

  const [locationAddress, setLocationAddress] = useState<Address | undefined>(
    getLocalAddress()
  );

  const getLocationAddress = async () => {
    const ip = await getPublicIpAddress();
    try {
      const res = await fetch(`https://ipapi.co/${ip}/json/`);
      if (!res.ok) {
        throw new Error('failed to fetch IP geodata');
      }
      const data = await res.json();
      const newAddress: Address = {
        postal: data.postal,
        city: data.city,
        state: data.region_code,
        country: data.country_code,
      };
      localStorage.setItem('userAddress', JSON.stringify(newAddress));
      setLoading(false);
      return newAddress;
    } catch (e) {
      setError(true);
      setLoading(false);
      return undefined;
    }
  };

  useEffect(() => {
    if (locationAddress || typeof window === 'undefined') {
      setLoading(false);
    } else {
      getLocationAddress().then((address) => {
        setLocationAddress(address);
      });
    }
  }, [locationAddress]);

  return { locationAddress, loading, error };
};

/**
 * The zip that the user has manually set to be used (assumed to be valid US zip)
 */
export const useCustomZip = () => {
  const getZipAddress = () => {
    if (typeof window === 'undefined') {
      return { zip: undefined, address: undefined };
    }
    return {
      zip: localStorage.getItem('postal') || undefined,
      address: localStorage.getItem('address') || undefined,
    };
  };

  const [zipAddress, setZipAddress] = useState<{
    zip: string | undefined;
    address: string | undefined;
  }>(getZipAddress());

  const setCustomZip = ({
    postal,
    address,
  }: {
    postal: string;
    address?: string;
  }) => {
    localStorage.setItem('postal', postal);
    if (address) {
      localStorage.setItem('address', address);
    } else {
      localStorage.removeItem('address');
    }
    setZipAddress({ zip: postal, address });
  };

  const clearCustomZip = () => {
    localStorage.removeItem('postal');
    localStorage.removeItem('address');
    setZipAddress({ zip: undefined, address: undefined });
  };

  return {
    setCustomZip,
    clearCustomZip,
    zip: zipAddress.zip,
    address: zipAddress.address,
  };
};
