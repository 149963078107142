import Icon, { IconIds } from '@/components/atoms/legacy/Icon';
import { Component } from '@/types/interfaces';
import React from 'react';

export enum TextButtonVariants {
  Primary = 'text-button-primary',
  Secondary = 'text-button-secondary',
  Tertiary = 'text-button-tertiary',
  Default = 'text-button-default',
}

export enum TextButtonSizes {
  Normal = 'text-button-normal',
  Small = 'text-button-small',
}

export interface TextButtonProps
  extends Component,
    Pick<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      'onClick' | 'disabled' | 'type'
    > {
  size?: TextButtonSizes;
  variant?: TextButtonVariants;
  prependIcon?: IconIds;
  appendIcon?: IconIds;
  children: React.ReactNode;
  ariaLabel?: string;
  textClassName?: string;
  iconColor?: string;
  iconFillColor?: string;
}

const colorMapping = {
  [TextButtonVariants.Primary]: '#28BE98',
  [TextButtonVariants.Secondary]: '#6A838E',
  [TextButtonVariants.Tertiary]: '#007BCC',
  [TextButtonVariants.Default]: '#000',
};

const TextButton: React.FC<TextButtonProps> = ({
  className = '',
  size = TextButtonSizes.Normal,
  variant = TextButtonVariants.Default,
  prependIcon,
  appendIcon,
  children,
  ariaLabel = '',
  textClassName = '',
  iconColor = '',
  iconFillColor = '',
  ...props
}) => {
  const finalIconColor = iconColor || colorMapping[variant];
  return (
    <button
      className={`text-button group ${variant} ${size} ${className}`}
      aria-label={ariaLabel}
      {...props}
    >
      {prependIcon && (
        <Icon
          iconId={prependIcon}
          strokeColor={finalIconColor}
          fillColor={iconFillColor || finalIconColor}
          width={size === TextButtonSizes.Normal ? '14' : '9'}
          height={size === TextButtonSizes.Normal ? '14' : '9'}
        />
      )}
      <span
        className={`group-hover:text-blue-dark group-hover:underline ${textClassName}`}
      >
        {children}
      </span>
      {appendIcon && (
        <Icon
          iconId={appendIcon}
          strokeColor={finalIconColor}
          fillColor={iconFillColor || finalIconColor}
          width={size === TextButtonSizes.Normal ? '14' : '9'}
          height={size === TextButtonSizes.Normal ? '14' : '9'}
        />
      )}
    </button>
  );
};

export default TextButton;
