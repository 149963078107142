import Heading, { HeadingLevels } from '@/components/atoms/legacy/Heading';
import { MakeTile } from '@/components/molecules/MakeTile';
import { CHOOSE_MAKE, HIDDEN_MAKES } from '@/lib/constants';

interface EVCompareMakeSelectionProps {
  makes: string[];
  selectedMake: string;
  handleMakeClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  handleMakeChange: () => void;
}

export const EVCompareMakeSelection: React.FC<EVCompareMakeSelectionProps> = ({
  makes,
  selectedMake,
  handleMakeClick,
  handleMakeChange,
}) => {
  return (
    <section className="mb-[60px] flex flex-col overflow-scroll">
      <Heading
        className="mb-[12px] ml-[4px] text-[clamp(1rem,1vw,1.25rem)] l:mb-[24px]"
        level={HeadingLevels.H4}
      >
        {CHOOSE_MAKE}
      </Heading>
      <div className={`flex flex-col gap-[10px]`}>
        {selectedMake === '' ? (
          <div className="flex flex-col gap-[10px] pb-[60px]">
            {makes
              .filter((make) => !HIDDEN_MAKES.includes(make))
              .map((make) => {
                return (
                  <MakeTile
                    make={make}
                    key={make}
                    selected={false}
                    handleMakeClick={handleMakeClick}
                  />
                );
              })}
          </div>
        ) : (
          <MakeTile
            make={selectedMake}
            needChange={true}
            selected={false}
            handleChange={handleMakeChange}
          />
        )}
      </div>
    </section>
  );
};
