import { IconId, IconIds } from '@/components/atoms/legacy/Icon';

export enum MakeColor {
  Black = '#080812',
  Red = '#A91008',
  Blue = '#102B72',
  Yellow = '#E5B60F',
  Green = '#083312',
}

const KNOWN_MAKES = [
  'Mercedes-Benz',
  'Audi',
  'Acura',
  'Cadillac',
  'Chrysler',
  'Genesis',
  'Honda',
  'Jaguar',
  'Jeep',
  'Kia',
  'Lexus',
  'Lucid',
  'Maserati',
  'Mazda',
  'Nissan',
  'Polestar',
  'Ram',
  'VinFast',
  'MINI',
  'Buick',
  'Porsche',
  'Rimac',
  'Fisker',
  'McLaren',
  'Dodge',
  'Alfa Romeo',
  'Ferrari',
  'FIAT',
  'GMC',
  'Tesla',
  'Mitsubishi',
  'Toyota',
  'BMW',
  'Subaru',
  'Ford',
  'Hyundai',
  'Land Rover',
  'Volkswagen',
  'Volvo',
  'Chevrolet',
  'Lamborghini',
  'Lincoln',
  'Lotus',
  'Rivian',
  'Rolls Royce',
  'Aston Martin',
  'Nu Ride',
  'Lordstown',
] as const;

const knownMakesSet = new Set<string>(KNOWN_MAKES);

export type KnownMake = (typeof KNOWN_MAKES)[number];

export const isKnownMake = (make: string): make is KnownMake => {
  return knownMakesSet.has(make);
};

const makesByColor: { [K in MakeColor]: KnownMake[] } = {
  [MakeColor.Black]: [
    'Mercedes-Benz',
    'Audi',
    'Acura',
    'Cadillac',
    'Chrysler',
    'Genesis',
    'Honda',
    'Jaguar',
    'Jeep',
    'Kia',
    'Lexus',
    'Lincoln',
    'Lucid',
    'Maserati',
    'Mazda',
    'Nissan',
    'Polestar',
    'Ram',
    'VinFast',
    'MINI',
    'Buick',
    'Porsche',
    'Rimac',
    'Rolls Royce',
    'Fisker',
    'McLaren',
    'Nu Ride',
    'Lordstown',
    'Dodge',
  ],
  [MakeColor.Red]: [
    'Alfa Romeo',
    'Ferrari',
    'FIAT',
    'GMC',
    'Tesla',
    'Mitsubishi',
    'Toyota',
  ],
  [MakeColor.Blue]: [
    'BMW',
    'Subaru',
    'Ford',
    'Hyundai',
    'Land Rover',
    'Volkswagen',
    'Volvo',
  ],
  [MakeColor.Yellow]: ['Chevrolet', 'Lamborghini', 'Lotus', 'Rivian'],
  [MakeColor.Green]: ['Aston Martin'],
};

export const colorsByMake: { [K in KnownMake]: MakeColor } = Object.entries(
  makesByColor
).reduce(
  (acc, [color, makes]) => {
    makes.forEach((make) => {
      acc[make] = color as MakeColor;
    });
    return acc;
  },
  {} as { [K in KnownMake]: MakeColor }
);

const lowerCaseMakeMap = KNOWN_MAKES.reduce<Record<string, KnownMake>>(
  (acc, make) => {
    acc[make.toLowerCase()] = make;
    return acc;
  },
  {}
);

export const getKnownMake = (untrustedMake: string) => {
  let knownMake: KnownMake | null = null;

  const lowerCaseMake = untrustedMake.toLowerCase();
  if (lowerCaseMake in lowerCaseMakeMap) {
    knownMake = lowerCaseMakeMap[lowerCaseMake];
  }

  return knownMake;
};

export const makeIconId = (make: string) => {
  const trimmedMake = make.trim();
  const iconId = `Make_${trimmedMake.replaceAll(' ', '').replaceAll('-', '')}`;

  if (!(iconId in IconIds)) {
    return null;
  }

  return iconId as IconId;
};
